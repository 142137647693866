body {
  background-color: #f65b40;
}

img {
  width: 100%;
  max-width: 500px;
}

.image-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}